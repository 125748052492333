var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("base-toolbar", {
    scopedSlots: _vm._u([
      {
        key: "toolbar-left",
        fn: function () {
          return [
            !_vm.selectedTeacher && !_vm.selectedWeek
              ? [
                  _vm.$currentUser.isDistrictAdmin
                    ? _c("v-autocomplete", {
                        staticClass: "mr-2",
                        staticStyle: {
                          "max-width": "190px",
                          "min-width": "190px",
                        },
                        attrs: {
                          label: _vm.$t("schoolLabel"),
                          "display:inline-flex": "",
                          "hide-details": "auto",
                          items: _vm.schoolItems,
                          outlined: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.currentSchoolId,
                          callback: function ($$v) {
                            _vm.currentSchoolId = $$v
                          },
                          expression: "currentSchoolId",
                        },
                      })
                    : _vm._e(),
                  _c("v-select", {
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      outlined: "",
                      "hide-details": "auto",
                      dense: "",
                      label: _vm.$t("yearLabel"),
                      items: _vm.adminSchoolYears,
                      "item-text": "yearName",
                      "return-object": "",
                    },
                    model: {
                      value: _vm.reportYear,
                      callback: function ($$v) {
                        _vm.reportYear = $$v
                      },
                      expression: "reportYear",
                    },
                  }),
                  _vm.hasData
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            color: "primary",
                            text: "",
                            outlined: "",
                            height: "40",
                          },
                          on: { click: _vm.showWeek },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("viewByWeek")))])]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.selectedTeacher
              ? [
                  _c(
                    "v-avatar",
                    {
                      staticClass: "mr-2 white--text",
                      staticStyle: { "font-weight": "lighter" },
                      attrs: { color: "primary", size: "2rem" },
                    },
                    [
                      _vm.hasText(
                        _vm.getTeacherItem(_vm.selectedTeacher).photoUrl
                      )
                        ? _c("v-img", {
                            attrs: {
                              src: _vm.getTeacherItem(_vm.selectedTeacher)
                                .photoUrl,
                            },
                          })
                        : _c("span", { staticClass: "body-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.getInitials(
                                  _vm.getTeacherItem(_vm.selectedTeacher)
                                )
                              )
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.selectedTeacher.displayName) +
                        ":   " +
                        _vm._s(_vm.selectedTeacher.yearName)
                    ),
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        color: "primary",
                        text: "",
                        outlined: "",
                        height: "40",
                      },
                      on: {
                        click: function ($event) {
                          _vm.selectedTeacher = null
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("allTeachersLabel")))])]
                  ),
                ]
              : _vm._e(),
            _vm.selectedWeek
              ? [
                  _c("v-select", {
                    staticStyle: { "max-width": "250px" },
                    attrs: {
                      outlined: "",
                      "hide-details": "auto",
                      dense: "",
                      label: _vm.$t("weekLabel"),
                      items: _vm.weeksList,
                      "item-text": "text",
                      "return-object": "",
                    },
                    model: {
                      value: _vm.reportWeek,
                      callback: function ($$v) {
                        _vm.reportWeek = $$v
                      },
                      expression: "reportWeek",
                    },
                  }),
                  _vm.getWeekIndex > 0
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            color: "primary",
                            text: "",
                            outlined: "",
                            height: "40",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getNextWeek(-1)
                            },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("priorLabel")))])]
                      )
                    : _vm._e(),
                  _vm.getWeekIndex + 1 < _vm.weeksList.length
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            color: "primary",
                            text: "",
                            outlined: "",
                            height: "40",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getNextWeek(1)
                            },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("nextLabel")))])]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        color: "primary",
                        text: "",
                        outlined: "",
                        height: "40",
                      },
                      on: {
                        click: function ($event) {
                          _vm.selectedWeek = null
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("allWeeksLabel")))])]
                  ),
                ]
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "toolbar-right",
        fn: function () {
          return [
            !_vm.selectedTeacher && !_vm.selectedWeek
              ? [
                  !_vm.yearPassed
                    ? _c(
                        "v-menu",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on: onMenu }) {
                                  return [
                                    _c(
                                      "pb-btn",
                                      _vm._g(
                                        {
                                          staticClass: "ml-2 my-1",
                                          attrs: {
                                            color: "primary",
                                            label: _vm.$t(
                                              "messageTeachersLabel"
                                            ),
                                            text: "",
                                            outlined: "",
                                          },
                                        },
                                        { ...onMenu }
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("fal fa-message"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            612901841
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openMessageEditor(true)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(_vm.$t("completeLabel"))),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openMessageEditor(false)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(_vm.$t("unsubmittedlabel"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.selectedWeek
              ? [
                  _c(
                    "v-menu",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: onMenu }) {
                              return [
                                _c(
                                  "pb-btn",
                                  _vm._g(
                                    {
                                      staticClass: "ml-2 my-1",
                                      attrs: {
                                        color: "primary",
                                        label: _vm.$t("messageTeachersLabel"),
                                        text: "",
                                        outlined: "",
                                      },
                                    },
                                    { ...onMenu }
                                  ),
                                  [_c("v-icon", [_vm._v("fal fa-message")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        612901841
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.openMessageEditor(true)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$t("completeLabel"))),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.openMessageEditor(false)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$t("unsubmittedlabel"))),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }