
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    BaseToolbar
  }
})
export default class TeacherSubmissionsToolbar extends BaseToolbar {
  get localComponent(): any {
    return this.currentPageInstance || {};
  }

  get schoolItems() {
    return this.localComponent.schoolItems || [];
  }

  get currentSchoolId() {
    return this.localComponent.currentSchoolId;
  }

  set currentSchoolId(val: any) {
    this.localComponent.currentSchoolId = val;
  }

  get adminSchoolYears() {
    return this.localComponent.adminSchoolYears || [];
  }

  get reportYear() {
    return this.localComponent.reportYear;
  }

  set reportYear(value: any) {
    this.localComponent.reportYear = value;
  }

  get hasData() {
    return this.localComponent.hasData;
  }

  get showWeek() {
    return this.localComponent.showWeek || this.emptyFunction;
  }

  get yearPassed() {
    return this.localComponent.yearPassed;
  }

  get openMessageEditor() {
    return this.localComponent.openMessageEditor || this.emptyFunction;
  }

  get selectedTeacher() {
    return this.localComponent.selectedTeacher;
  }

  set selectedTeacher(value: any) {
    this.localComponent.selectedTeacher = value;
  }

  get selectedWeek() {
    return this.localComponent.selectedWeek;
  }

  set selectedWeek(value: any) {
    this.localComponent.selectedWeek = value;
  }

  get weeksList() {
    return this.localComponent.weeksList || [];
  }

  get reportWeek() {
    return this.localComponent.reportWeek;
  }

  set reportWeek(value: any) {
    this.localComponent.reportWeek = value;
  }

  get getWeekIndex() {
    return this.localComponent.getWeekIndex;
  }

  get getNextWeek() {
    return this.localComponent.getNextWeek || this.emptyFunction;
  }

  get hasText() {
    return this.localComponent.hasText || this.emptyFunction;
  }

  get getTeacherItem() {
    return this.localComponent.getTeacherItem || this.emptyFunction;
  }

  get getInitials() {
    return this.localComponent.getInitials || this.emptyFunction;
  }
}
